var api = "https://api.qingcigame.com/jpweb";
var index = '';
var is_jp_email = '';
var invited_code = '';
var go_page = window.location.href.split('?page=')[1];
var faqListChange = true;
var register_switch = false;
var obj = {
    index: function() {
        this.preloading(); // 预加载
        this.indexAjax(); // 首页接口请求
        this.jqslideTxtBox(); // 游戏咨询信息切换
        this.Textlength(28, 25); // 内容字体长度超过40，标题字体长度超过25
        this.videoAndImg(); // 视频和图片预览
        this.indexBind(); // 首页按钮
        this.bookcaseBind(); // 书柜辞典模块
    },
    // 新闻列表
    newsList: function() {
        this.newsZxList(); // 新闻最新列表
        this.newsTabPage(); // 最新、综合、活动跳转
    },
    // 新闻详情
    newsDetails: function() {
        this.newsInfo();
        this.scroll();
    },
    // 游戏攻略列表
    intrList: function() {
        this.newsIntrList(); // 新闻最新列表
        this.newsTabPage(); // 最新、综合、活动跳转
    },
    // 视频中心
    videoCenter: function() {
        this.videoCenterAjax(); // 获取视频中心视频
        this.videoSwitch(); // 选择播放的视频
    },
    // 视频详情
    videoDetails: function() {
        this.videoDetailsAjax(); // 获取详细的视频
    },
    // 同名动画
    sameNameAni: function() {
        this.sameNameAjax(); // 获取同名动画视频
        this.videoSwitch(); // 选择播放的视频
        this.channel(); // 选择不同频道
    },
    // 漫画续集
    comicSequel: function() {
        this.comicSequelAjax(); // 获取漫画续集
    },
    // wiki-贵重物品
    wikiPrecious: function() {
        var rarity, deviation, deviation, mosaic_skill, halo_effect, column, order, page, url;
        this.getWikiData(); // 获取筛选的参数
        this.filtering(); // wiki-贵重物品-筛选
        this.wikiearch(); // wiki-贵重物品-搜索
        this.wikiTabInfo(); // tab切换信息
        this.wikiPopup(); // 弹窗的开关查看详情
    },
    // wiki资料站
    wikiIndex: function() {
        this.wikiIndexAjax();
    },
    contactIndex: function() {
        this.contactBind();
    },
    contactBind: function() {

        var wj_url = [];
        var name_url = [];

        // 开关协议按钮
        $('.checked').on('click', function(event) {
            $(this).hasClass('on') ? $(this).removeClass('on') : $(this).addClass('on')
        });

        // 更新图片地址
        $("#upload").on("change", "input[type='file']", function() {
            var filePath = $(this).val();
            var arr = filePath.split('\\');
            var fileName = arr[arr.length - 1];
            $(".file-info").html(fileName);
            $('.file-list').find('li').length + 1 >= 10 ? $("#upload").hide() : $("#upload").show();

            if ($('.file-info').html() !== '選択されていません') {
                $(".file-info").html('選択されていません');
                var index = layer.load(2, { shade: [0.1, '#fff'] });
                var form = document.getElementById('upload'),
                    formData = new FormData(form);
                $.ajax({
                    url: "https://api.qingcigame.com/jpweb/upload",
                    type: "post",
                    data: formData,
                    processData: false,
                    contentType: false,
                    success: function(res) {
                        if (res.code == 200) {
                            var addFileHtml = '<li>' +
                                '<a href="javascript:;" data="' + res.data.oss_url + '">キャンセル</a>' +
                                '<span class="file-name">' + fileName + '</span>' +
                                '</li>';
                            $('.file-list ul').append(addFileHtml);
                            name_url.push(fileName);
                            wj_url.push(res.data.oss_url)
                            layer.close(index);
                        } else {
                            layer.msg('アップロードするファイルは10 MBを超えてはいけません', { time: 2000 });
                            layer.close(index);
                        }
                    },
                    error: function(err) {
                        layer.close(index);
                        layer.msg("アップロードするファイルは10 MBを超えてはいけません", err);
                    }
                })
            }
        })

        $('.file-list').on('click', 'li a', function(event) {
            var index = layer.load(2, { shade: [0.1, '#fff'] });
            setTimeout(function() {
                layer.close(index);
                var is_data = $(this).attr('data');
                var is_name = $(this).siblings('span').html();
                wj_url = wj_url.filter(function(item, index, array) {
                    return item != is_data;
                });
                name_url = name_url.filter(function(item, index, array) {
                    return item != is_name;
                });
                console.log(name_url);
                $(this).parents('li').remove();
                console.log($('.file-list').find('li').length)
                $('.file-list').find('li').length >= 10 ? $("#upload").hide() : $("#upload").show();
            }, 500);
        });

        $("textarea[name=contact_detail]").keyup(
            function() {
                var count = $(this).val().length;
                $('.font-length').html('(' + count + '/400)');
            }
        );

        $('.verification-btn').on('click', function(event) {
            var trainer_id = $("input[name=trainer_id]").val(); // 角色id            
            var u_id = $("input[name=u_id]").val(); // uid
            var app_ver = $("input[name=app_ver]").val(); // 版本号
            var os_ver = $("input[name=os_ver]").val(); // 服务环境
            var device_name = $("input[name=device_name]").val(); // 设备端
            var contact_kind = $(".js-select[name=contact_kind] option:selected").val(); // 问题类型            
            var file = $("input[name=file]").val(); // 添加文件
            var device_type = $(".js-select[name=device_type] option:selected").val(); // 环境类型
            var trainer_name = $("input[name=trainer_name]").val(); // 游戏中角色名称
            var issue_date = $("input[name=issue_date]").val(); // 问题时间
            var issue_hour = $(".js-select[name=issue_hour] option:selected").val(); // 时
            var issue_minute = $(".js-select[name=issue_minute] option:selected").val(); // 分
            var contact_detail = $("textarea[name=contact_detail]").val(); // 详细描述
            var mailaddress = $("input[name=mailaddress]").val(); // 邮箱
            var checkbox = $("input[type=checkbox").attr("checked"); // 协议是否开启
            var not_entered = '未入力';
            var verification = /([a-z0-9]*[a-z0-9]+)*@([a-z0-9]*[-_]?[a-z0-9]+)+[.][a-z]{2,3}([.][a-z]{2})?/i;

            console.log(file)
            console.log('输入的文案长度', contact_detail.length)
            if (contact_detail.length > 400) {
                layer.msg("文字数の上限を超えました。ご調整ください。", { time: 2000 });
                return
            }
            var index = layer.load(2, { shade: [0.1, '#fff'] });
            $('.center-main').fadeOut();
            $(document).scrollTop(0);
            setTimeout(function() {
                layer.close(index);
                $('.center-main').fadeIn();

                function typePd(judge, class1, class2) {
                    if (judge) {
                        $(class1).show();
                        $(class2).addClass('error')
                    } else {
                        $(class1).hide();
                        $(class2).removeClass('error')
                    }
                }
                var must0 = typePd(u_id == '', '.type-0', 'input[name=u_id]');
                var must1 = typePd(contact_kind == 0, '.type-1', '.js-select[name=contact_kind]');
                var must2 = typePd(device_type == 0, '.type-2', '.js-select[name=device_type]');
                var must3 = typePd(contact_detail == '', '.type-3', 'textarea[name=contact_detail]');
                var must4 = typePd(mailaddress == '', '.type-4', 'input[name=mailaddress]');
                var must5 = typePd(!verification.test(mailaddress), '.type-4', 'input[name=mailaddress]');
                var must6 = typePd(!$('.checked').hasClass('on'), '.type-5', 'input[type=checkbox]');

                var all_meet = u_id !== '' && contact_kind !== '0' && device_type !== '0' && contact_detail !== '' && mailaddress !== '' && $('.checked').hasClass('on') && verification.test(mailaddress);
                if (all_meet) {

                    trainer_id == '' ? $('.trainer_id').html(not_entered) : $('.trainer_id').html(trainer_id);
                    u_id == '' ? $('.u_id').html(not_entered) : $('.u_id').html(u_id);
                    file == '' ? $('.file').html(not_entered) : $('.file').html(name_url.toString());
                    app_ver == '' ? $('.app_ver').html(not_entered) : $('.app_ver').html(app_ver);
                    os_ver == '' ? $('.os_ver').html(not_entered) : $('.os_ver').html(os_ver);
                    device_name == '' ? $('.device_name').html(not_entered) : $('.device_name').html(device_name);
                    contact_kind == '' ? $('.contact_kind').html(not_entered) : $('.contact_kind').html(contact_kind);
                    device_type == '' ? $('.device_type').html(not_entered) : $('.device_type').html(device_type);
                    trainer_name == '' ? $('.trainer_name').html(not_entered) : $('.trainer_name').html(trainer_name);
                    issue_date == '' ? $('.issue_date').html(not_entered) : $('.issue_date').html(issue_date + '，' + issue_hour + ':' + issue_minute);
                    // issue_point     == '' ? $('.issue_point').html(not_entered)     : $('.issue_point').html(issue_point);
                    contact_detail == '' ? $('.contact_detail').html(not_entered) : $('.contact_detail').html(contact_detail);
                    mailaddress == '' ? $('.mailaddress').html(not_entered) : $('.mailaddress').html(mailaddress);

                    $('#error-area').hide();
                    $('.center-main').hide();
                    $('.center-main-confirm').show();
                    layer.close(index);

                    return
                }
                $('#error-area').show();
            }, 1000)
        });

        // 返回上一页
        $('.return-btn').click(function(event) {
            var index = layer.load(2, { shade: [0.1, '#fff'] });
            setTimeout(function() {
                layer.close(index);
                $(document).scrollTop(0);
                $('.center-main').show();
                $('.center-main-confirm, .feedback-results').hide();
            }, 1000)
        });

        $('.send-btn').click(function(event) {
            var trainer_id = $("input[name=trainer_id]").val(); // 角色id  
            var u_id = $("input[name=u_id]").val(); // uid          
            var app_ver = $("input[name=app_ver]").val(); // 版本号
            var os_ver = $("input[name=os_ver]").val(); // 服务环境
            var device_name = $("input[name=device_name]").val(); // 设备端
            var contact_kind = $(".js-select[name=contact_kind] option:selected").val(); // 问题类型
            var device_type = $(".js-select[name=device_type] option:selected").val(); // 环境类型
            var trainer_name = $("input[name=trainer_name]").val(); // 游戏中角色名称
            var issue_date = $("input[name=issue_date]").val(); // 问题时间
            var issue_hour = $(".js-select[name=issue_hour] option:selected").val(); // 时
            var issue_minute = $(".js-select[name=issue_minute] option:selected").val(); // 分
            var issue_point = $("input[name=issue_point]").val(); // 发生问题处
            var contact_detail = $("textarea[name=contact_detail]").val(); // 详细描述
            var mailaddress = $("input[name=mailaddress]").val(); // 邮箱
            var checkbox = $("input[type=checkbox").attr("checked"); // 协议是否开启
            var index = layer.load(2, { shade: [0.1, '#fff'] });

            $.ajax({
                url: api + "/consule/upload",
                type: "POST",
                data: {
                    user_id: trainer_id, // 用户id
                    u_id: u_id, // 用户id
                    version: app_ver, // 应用版本
                    systems: os_ver, // 操作系统版本
                    equipment_name: device_name, // 设备名称
                    content_type: contact_kind, // 咨询内容类型
                    game_nickname: trainer_name, // 游戏昵称
                    use_environment: device_type, // 使用环境
                    time: issue_date + '-' + issue_hour + '-' + issue_minute, // 问题的日期和时间
                    problem: issue_point, // 出现问题的地方
                    dateiled_content: contact_detail, // 咨询内容
                    user_email: mailaddress, // 电子邮件地址
                    video_url: 0,
                    url: wj_url.toString() // 文件地址
                },
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    if (json.code == 200) {
                        layer.close(index);
                        $(document).scrollTop(0);
                        $('.center-main-confirm').hide();
                        $('.feedback-results').fadeIn();
                        $('.results').html(json.message);
                    } else {
                        layer.msg(json.message, { time: 2000 });
                    }
                },
                error: function() {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                },
                complete: function() {
                    layer.close(index);
                }
            })
        });


        $('#input-date').datetimepicker({
            onGenerate: function(ct) {},
            timepicker: false,
            format: 'Y-m-d',
            lang: 'ja'
        });
    },
    feedbackIndex: function() {
        this.feedbackBind();
    },
    feedbackBind: function() {
        $('.feedback-btn').on('click', function(event) {

            var contact_detail = $("textarea[name=contact_detail]").val(); // 详细描述
            $('.center-main').fadeOut();

            var index = layer.load(2, { shade: [0.1, '#fff'] });
            $(document).scrollTop(0);
            setTimeout(function() {
                layer.close(index);
                $('.center-main').fadeIn();

                function typePd(judge, class1, class2) {
                    if (judge) {
                        $(class1).show();
                        $(class2).addClass('error')
                    } else {
                        $(class1).hide();
                        $(class2).removeClass('error')
                    }
                }
                var must3 = typePd(contact_detail.length < 6, '.type-1', 'textarea[name=contact_detail]');

                var all_meet = contact_detail.length >= 6;
                if (all_meet) {
                    $('#error-area').hide();
                    $('.center-main').hide();
                    $('.center-main-confirm').show();

                    contact_detail == '' ? $('.contact_detail').html(not_entered) : $('.contact_detail').html(contact_detail);
                    return
                }
                $('#error-area').show();
            }, 1000)
        });

        // 返回上一页
        $('.return-btn').click(function(event) {
            var index = layer.load(2, { shade: [0.1, '#fff'] });
            setTimeout(function() {
                layer.close(index);
                $(document).scrollTop(0);
                $('.center-main').show();
                $('.center-main-confirm, .feedback-results').hide();
            }, 1000)
        });

        var changeS = true;
        $('.mail-sending-btn').click(function(event) {
            var contact_detail = $('.contact_detail').html();
            if (changeS) {
                changeS = false;
                var index = layer.load(2, { shade: [0.1, '#fff'] });
                $(document).scrollTop(0);
                $.ajax({
                    url: api + "/consule/opinion",
                    type: "POST",
                    data: {
                        dateiled_content: contact_detail
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function(json) {
                        if (json.code == 200) {
                            setTimeout(function() {
                                layer.close(index);
                                changeS = true;
                                $('.feedback-main-confirm').hide();
                                $('.feedback-results').fadeIn();
                                $('.results').html(json.message);
                            }, 2000);
                        }
                    },
                    error: function() {
                        layer.msg("サーバーに接続できません", { time: 2000 });
                    },
                    complete: function() {

                    }
                })
            }
        });
    },
    // faq页面
    faqIndex: function() {
        this.faqBind();
        this.faqListAjax(1);
    },
    faqBind: function() {
        var id = 1;
        $('.faq-tab-title').on('click', 'a', function(event) {
            obj.faqListAjax($(this).attr('id'));
            $(this).addClass('on').siblings().removeClass('on');
            faqListChange = false;
        });

        $('.faq-list').on('click', 'a', function(event) {
            if ($(this).parent('li').hasClass('li-show')) {
                $(this).parent('li').removeClass('li-show');
            } else {
                $(this).parent('li').addClass('li-show');
            }
        });

        $('.search-btn').on('click', function(event) {
            var searchIpu = $('.search-input').val()
            if (searchIpu == '') {
                $('.faq-tab-title a').show();
                $('.faq-result').find('.result-num').html('0');
                $('.faq-tab-title a:nth-child(1)').addClass('on').siblings().removeClass('on');
                obj.faqListAjax(1);
                return
            }
            var index = layer.load(2, { shade: [0.1, '#fff'] });
            $.ajax({
                url: api + "/consule/faq",
                type: "GET",
                data: {
                    title: searchIpu
                },
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    if (json.code == 200) {
                        $('.faq-tab-title a').hide();
                        $('.new-list ul li').remove();
                        // 渲染列表
                        $.each(json.data.commonProbelm, function(index, values) {
                            var dtHtml = '<li>' +
                                '<a href="javascript:;">' +
                                '<i></i><em></em>' +
                                '<p class="lside">' +
                                '<span class="comment">' + values.title + '</span>' +
                                '</p>' +
                                '</a>' +
                                '<div class="hide-info">' + values.content + '</div>' +
                                '</li>';
                            $('.new-list ul').append(dtHtml);
                            $('.new-list').addClass('new-list-show');
                        });
                        $('.faq-result').find('.result-num').html($('.new-list ul li').length);
                    }
                },
                error: function() {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                },
                complete: function() {
                    layer.close(index);
                }
            })
        });
    },
    // faq列表
    faqListAjax: function(id) {
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        $.ajax({
            url: api + "/consule/faq",
            type: "GET",
            data: {
                tag_id: id
            },
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    $('.new-list ul li').remove();
                    // 标题渲染
                    if (faqListChange == true) {
                        $.each(json.data.tag, function(index, values) {
                            var titleHtml = '<a id="' + values.id + '">' + values.faq_tag + '</a>';
                            $('.faq-tab-title').append(titleHtml);
                        });
                    }

                    // 渲染列表
                    $.each(json.data.commonProbelm, function(index, values) {
                        var dtHtml = '<li>' +
                            '<a href="javascript:;">' +
                            '<i></i><em></em>' +
                            '<p class="lside">' +
                            '<span class="comment">' + values.title + '</span>' +
                            '</p>' +
                            '</a>' +
                            '<div class="hide-info">' + values.content + '</div>' +
                            '</li>';
                        $('.new-list ul').append(dtHtml);
                        $('.new-list').addClass('new-list-show');
                    });
                } else {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("サーバーに接続できません", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })

    },
    // 首页暂未开放的几个按钮
    indexBind: function() {

        $('.flame').hover(function() {
            $(this).find('i').addClass('disappear')
        }, function() {
            $(this).find('i').removeClass('disappear')
        });

        $('.role-ani').click(function(event) {
            var _this = $(this);
            if (!_this.hasClass('rubberBand2'))
                _this.addClass('rubberBand2')
            setTimeout(function() { _this.removeClass('rubberBand2') }, 1000);
        });

        // 规则弹窗
        $('.rule-btn').click(function(event) {
            $('.rule-wrap').fadeIn();
        });

        // 点击邮箱按钮开启弹窗
        $('.mailbox-btn').click(function(event) {
            if (register_switch == true) {
                $('.success-wrap').fadeIn();
                return
            }
            $('.mailbox-wrap').fadeIn();
        });

        // 查看奖励列表
        $('.reward-btn').click(function(event) {
            $('.invitation-award-wrap').fadeIn();
        });

        // 开启邀请奖励弹窗
        $('.invitation-btn').click(function(event) {
            $('.popup').fadeOut();
            $('.invitation-award-wrap').fadeIn();
        });

        // 预约入口 && 邀请奖励入口(->邀请好友)
        $('.invitation-btn-02').click(function(event) {
            $('.popup').fadeOut();
            $('.invitation-wrap').fadeIn();
            // if(is_jp_email == '') {
            //     // 当前没有的登陆过则前往邮箱验证登录
            //     $('.mailbox-wrap').fadeIn();
            // } else {
            //     // 否则前往邀请地址弹窗                
            //     index = layer.load(2, {shade: [0.1, '#fff']});
            //     $.ajax({
            //         url: api + "/reserve/user/url",
            //         type: "POST",
            //         xhrFields: {
            //             withCredentials: true
            //         },
            //         data: {
            //             jp_email: is_jp_email
            //         },
            //         dataType: 'json',
            //         success: function (json) {
            //             if (json.code == 200) {
            //                 $('.invitation-wrap').fadeIn();
            //                 $('#bar').html(window.location.href.split("?")[0] + '?code=' + json.data.code);
            //             } else {
            //                 layer.msg(json.message, {time: 2000});
            //             }
            //         },
            //         complete: function () {
            //             layer.close(index);
            //         }
            //     });
            // }
        });

        // $('.return-btn').click(function (event) {
        //     $('.invitation-award-wrap').fadeIn();
        //     $('.end-invitation-wrap').fadeOut();
        // });

        // 点击邮箱按钮开启弹窗
        $('.det-btn').click(function(event) {
            $('.world-outlook-wrap').fadeIn();
        });

        // 点击隐藏电子邮箱输入
        $('.mailbox-wrap').on('click', '.close-btn', function(event) {
            $('.popup').fadeOut();
        });

        // 请求获取短信验证Ajax
        $('.obtain-verification').on('click', 'a', function() {
            var Email = $('.Email-inp').val();
            if (Email == '') {
                layer.msg("メールを入力してください!", { time: 2000 });
                return
            }
            index = layer.load(2, { shade: [0.1, '#fff'] });
            $.ajax({
                url: api + "/reserve/user/code",
                type: "POST",
                xhrFields: {
                    withCredentials: true
                },
                data: {
                    jp_email: Email
                },
                dataType: 'json',
                success: function(json) {
                    layer.msg(json.message, { time: 2000 });
                    if (json.code == 200) {
                        time();
                    }
                },
                complete: function() {
                    layer.close(index);
                }
            });
        });

        // 600s倒计时
        var wait = 600;

        function time() {
            if (wait == 0) {
                $('.obtain-verification span a').remove();
                $('.obtain-verification span').html("<a class='verify-acquisition-btn'>認証コードを受け取る</a>");
                wait = 600;
            } else {
                $('.obtain-verification span a').remove();
                $('.obtain-verification span').html("<b>" + wait + "s</b>");
                wait--;
                setTimeout(function() {
                    time()
                }, 1000)
            }
        }

        // 预约登录
        $('.appointment-btn').click(function(event) {
            var Email = $('.Email-inp').val();
            var code = $('.code-inp').val();
            if (Email == '') {
                layer.msg("メールを入力してください!", { time: 2000 });
                return
            }
            if (code == '') {
                layer.msg("認証コードを入力してください!", { time: 2000 });
                return
            }
            index = layer.load(2, { shade: [0.1, '#fff'] });
            invited_code = window.location.href.split("?code=")[1];
            setTimeout(function() {
                $.ajax({
                    url: api + "/reserve/user/info",
                    type: "POST",
                    xhrFields: {
                        withCredentials: true
                    },
                    data: {
                        jp_email: Email,
                        code: code,
                        be_invited_code: invited_code,
                    },
                    dataType: 'json',
                    success: function(json) {
                        // 第一次 || 第二次预约操作进入
                        if (json.code == 200 || json.code == 404) {
                            register_switch = true;
                            var _info = json.data.info;
                            var code_one = _info.secret_order_one;
                            var code_two = _info.secret_order_two;
                            var code_three = _info.secret_order_three;
                            is_jp_email = Email; // 将邮箱进行存储
                            var judge1 = judgeChange(code_one, '.type_1');
                            var judge2 = judgeChange(code_two, '.type_3');
                            var judge3 = judgeChange(code_three, '.type_5');

                            $('.popup').fadeOut();
                            $('.success-wrap').fadeIn();

                            $('.success-wrap').removeClass('success-wrap2');
                            if (_info.login_times > 1) {
                                $('.success-wrap').addClass('success-wrap2');
                            }
                        } else {
                            layer.msg(json.message, { time: 2000 });
                        }
                    },
                    complete: function() {
                        layer.close(index);
                    }
                }, 1000);
            });
        });

        function judgeChange(is_Data, is_class) {
            if (is_Data !== '') {
                $(is_class).find('b').html(is_Data);
                $(is_class).find('a').removeClass('wdc');
            }
        }

        $('.li-code').on('click', 'a', function(event) {
            $('.popup').fadeOut();
            $('.invitation-wrap').fadeIn();
            // $(this).hasClass('wdc') ? layer.msg('報酬はまだ達していません!', {time: 2000}) : $(this).fadeOut();
        });

        // var Media = document.getElementById('media-play');
        // $('.popup').on('click', '.close-btn', function(event) {
        //     $('.video-wrap, .popup-video').fadeOut();
        //     Media.pause();
        // });

        $('.popup').on('click', '.ok-btn', function(event) {
            $('.popup').fadeOut();
        });
    },
    // 书柜辞典模块
    bookcaseBind: function() {
        var dictionaries_data = '';
        var sort_data = '';

        $('.bookcase-box').on('click', 'li', function(event) {
            var data_id = $(this).attr('data');
            sort_data = $(this).attr('sort');
            var index = layer.load(2, { shade: [0.1, '#fff'] });
            for (var i = 14; i >= 1; i--) {
                $('.popup-business-card').removeClass('card-' + i + '');
            };
            $.ajax({
                url: api + "/dictionary/list",
                // url: "https://tapi.qingcigame.com/jpweb/dictionary/list",
                type: "POST",
                xhrFields: {
                    withCredentials: true
                },
                data: {
                    area_id: data_id,
                },
                success: function(json) {
                    if (json.code == 200) {
                        var _data = json.data.illustrate_list.data;
                        $('.card-box ul li').remove();
                        $('.popup-business-card').fadeIn().addClass('card-' + sort_data + '');
                        $.each(_data, function(index, values) {
                            var bookcaseHtml = '<li>' +
                                '<span class="span-1">' + values.name + '</span>' +
                                '<span class="span-2">' + values.pseudonym + '</span>' +
                                '<span class="span-3">' + values.introduce + '</span>' +
                                '</li>';
                            $('.card-box ul').append(bookcaseHtml);
                        });
                        return
                    }
                    layer.msg(json.message, { time: 2000 });
                },
                error: function() {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                },
                complete: function() {
                    layer.close(index);
                }
            })
        });

        $('.dictionaries').click(function(event) {
            for (var i = 2; i >= 1; i--) { $('.bookcase-box-' + i + ' li').remove() };
            var index = layer.load(2, { shade: [0.1, '#fff'] });
            if (dictionaries_data == '') {
                $.ajax({
                    url: api + "/area/list",
                    // url: "https://tapi.qingcigame.com/jpweb/area/list",
                    type: "GET",
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function(json) {
                        if (json.code == 200) {
                            dictionaries_data = json;
                            $('.bookcase-wrap').fadeIn();
                            if (json.data.area_list[1] !== undefined) {
                                $.each(json.data.area_list[1], function(index, values) {
                                    var bookcaseHtml = '<li class="ani-btn" sort="' + values.sort + '" data="' + values.id + '">' +
                                        '<img src="' + values.area_image + '">' +
                                        '</li>';
                                    $('.bookcase-box-1').append(bookcaseHtml);
                                });
                            }
                            if (json.data.area_list[2] !== undefined) {
                                $.each(json.data.area_list[2], function(index, values) {
                                    var bookcaseHtml = '<li class="ani-btn" sort="' + values.sort + '" data="' + values.id + '">' +
                                        '<img src="' + values.area_image + '">' +
                                        '</li>';
                                    $('.bookcase-box-2').append(bookcaseHtml);
                                });
                            }
                            return
                        }
                        layer.msg(json.message, { time: 2000 });
                    },
                    error: function() {
                        layer.msg("サーバーに接続できません", { time: 2000 });
                    },
                    complete: function() {
                        layer.close(index);
                    }
                })
            } else {
                for (var i = 14; i >= 1; i--) {
                    $('.popup-business-card').removeClass('card-' + i + '');
                };
                $('.bookcase-wrap').fadeIn();
                if (dictionaries_data.data.area_list[1] !== undefined) {
                    $.each(dictionaries_data.data.area_list[1], function(index, values) {
                        var bookcaseHtml = '<li class="ani-btn" sort="' + values.sort + '"  data="' + values.id + '">' +
                            '<img src="' + values.area_image + '">' +
                            '</li>';
                        $('.bookcase-box-1').append(bookcaseHtml);
                    });
                }
                if (dictionaries_data.data.area_list[2] !== undefined) {
                    $.each(dictionaries_data.data.area_list[2], function(index, values) {
                        var bookcaseHtml = '<li class="ani-btn" sort="' + values.sort + '"  data="' + values.id + '">' +
                            '<img src="' + values.area_image + '">' +
                            '</li>';
                        $('.bookcase-box-2').append(bookcaseHtml);
                    });
                }
                layer.close(index);
            }
        });

        $('.close-bg').click(function(event) {
            $('.bookcase-wrap').fadeOut();
        });

        $('.popup-business-card .card-close-btn').click(function(event) {
            $('.popup-business-card').fadeOut();
        });
    },
    // 复制相关方法
    clipboard: function() {
        var clipboard = new Clipboard('.copy-btn');
        clipboard.on('success', function(e) {
            layer.msg('コピー成功!', { time: 2000 });
            e.clearSelection();
        });

        clipboard.on('error', function(e) {
            console.error('Action:', e.action);
            console.error('Trigger:', e.trigger);
        });
    },
    // 图片资源预加载
    preloading: function() {
        //图片预加载
        var queue = new createjs.LoadQueue();
        queue.on("progress", function(e) {
            //加载中的进度
            if (e.progress * 100 >= 10) {
                $(".loding_time span").width(parseInt(e.progress * 100) + '%')
            };
            var numbers = e.progress * 100;
        }, this);
        queue.on("complete", function(e) {
            //加载完成执行操作
            var _this = this;
            $('.loding_time').remove();
            $('.preloading').addClass('preloading-ani').fadeOut(1000);
            $('.index').addClass('index-show');
            this.indexSwiper();
            if (go_page == undefined) {
                // pv暂时未提供，先隐藏
                // this.defaultVideo();
            }
            setTimeout(function() {
                $('.preloading').remove();
                _this.twitterShare(); // twitter分享
            }, 1000)
        }, this);
        queue.loadManifest([
            //加载图片，不包括后台的
            'images/pc/m_dt_sz-5c4ca25bcc.png',
            'images/pc/m_dt_t-d892b8ad0b.png',
            'images/pc/m_jdt-31fdd64c97.png',
            'images/pc/header_bg-58e43493cf.png',
            'images/pc/snail_ke-fda25e0735.png',
            'images/pc/snail-9998727df1.png',
            'images/pc/index_bg3-065148c6c7.png'
        ]);
    },
    defaultVideo: function() {
        var src = 'https://download4.qcplay.com/file/video_30m.mp4';
        $('.play-btn').fadeIn().attr('data', src);
        $('.video-wrap, .popup-video').fadeIn().find('iframe').attr('src', $('.play-btn').attr('data')).removeClass('ani-btn');

        $('.play-btn').on('click', function(event) {
            $('.video-wrap, .popup-video').fadeIn().find('iframe').attr('src', $('.play-btn').attr('data')).removeClass('ani-btn');
        });
        $('.popup').on('click', '.close-btn', function(event) {
            $('.video-wrap, .popup-video').find('iframe').attr('src', '');
        });
    },
    // 首页接口请求
    indexAjax: function() {
        // var index = layer.load(2, {shade: [0.1, '#fff']});
        var _this = this;
        $.ajax({
            // url: "https://tapi.qingcigame.com/jpweb/index",
            url: api + "/index",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    var zonghe = json.data.articleList.zonghe;
                    var activity = json.data.articleList.activity;
                    var cartoon = json.data.articleList.cartoon;
                    var jinjie = json.data.articleList.jinjie;
                    var mengxin = json.data.articleList.mengxin;
                    var video = json.data.articleList.video;
                    var jijie = json.data.articleList.jijie;
                    var ulr = json.data.articleList.ulr;
                    var spec = json.data.articleList.spec;
                    var introduction_index = json.data.articleList.introduction_index;
                    var beginner_guide = json.data.articleList.beginner_guide;
                    var intermediate = json.data.articleList.intermediate;
                    var xingtai = json.data.articleList.xingtai;
                    var explore = json.data.articleList.explore;
                    // url地址
                    $('.app-btn').attr('href', ulr[0].get_url); // App_Store
                    $('.google-btn').attr('href', ulr[1].get_url); // Google_Play
                    $('.amazon-btn').attr('href', ulr[10].get_url); // Amazon

                    $('.twitter-btn, .twitter-share-btn, .hot-activities-btn, .twitter-timeline').attr('href', ulr[2].get_url); // Twitter
                    $('.discord-btn').attr('href', ulr[9].get_url); // Discord
                    $('.open-chat-btn').attr('href', ulr[8].get_url); // LINE Open Chat
                    $('.line-btn, .line-share-btn').attr('href', ulr[3].get_url); // LINE
                    $('.facebook-btn, .facebook-share-btn').attr('href', ulr[4].get_url); // Facebook
                    var youtube_url = 'https://www.youtube.com/@user-gn4hx5rg3m';
                    $('.youtube-btn').attr('href', youtube_url); // youtube

                    // spec信息
                    $('.snail-spec .name').html(spec.name)
                    $('.snail-spec .type').html(spec.type)
                    $('.snail-spec .game_content').html(spec.game_content)
                    $('.snail-spec .server_time').html(spec.server_time)
                    $('.snail-spec .price').html(spec.price)
                    $('.snail-spec .download').html(spec.download)
                    $('.snail-spec .model').html(spec.model)
                    $('.snail-spec .rd').html(spec.rd)

                    // 综合新闻
                    $.each(zonghe, function(index, values) {
                        var zongheHtml = '<li>' +
                            '<a href="news-details.html?id=' + values.id + '">' +
                            '<h2>' + values.article_title + '</h2>' +
                            '<p>' + values.article_excerpt + '</p>' +
                            '<span>' + values.created_at + '</span>' +
                            '</a>' +
                            '</li>';
                        $('.zonghe').append(zongheHtml);
                    });

                    // 活动新闻
                    $.each(activity, function(index, values) {
                        var activityHtml = '<li>' +
                            '<a href="news-details.html?id=' + values.id + '">' +
                            '<h2>' + values.article_title + '</h2>' +
                            '<p>' + values.article_excerpt + '</p>' +
                            '<span>' + values.created_at + '</span>' +
                            '</a>' +
                            '</li>';
                        $('.activity').append(activityHtml);
                    });
                    $('.content-05 .left-img').find('a').attr('href', 'news-details.html?id=' + activity[0].id);

                    // 漫画 1-10正常显示，超过9为加号
                    if (cartoon.length >= 9) {
                        cartoon = cartoon.splice(0, 9);
                        setTimeout(() => {
                            $('.cartoon').append('<li><a href="comic-sequel.html?id=13"><h2>+</h2></a></li>');
                        }, 0);
                    }

                    $.each(cartoon, function(index, values) {
                        var cartoonHtml = '<li>' +
                            '<a href="news-details.html?id=' + values.id + '">' +
                            '<h2>' + values.article_title + '</h2>' +
                            '</a>' +
                            '</li>';
                        $('.cartoon').append(cartoonHtml);
                    });

                    // // 攻略全部信息-首页
                    // $.each(introduction_index, function(index, values) {
                    //     var introduction_index = '<li>'+
                    //         '<a href="introduction-details.html?id='+values.id+'">'+
                    //             '<h2>'+values.article_title+'</h2>'+
                    //             '<p>作者：'+values.origin+'</p>'+
                    //         '</a>'+
                    //     '</li>';
                    //     $('.introduction-index').append(introduction_index);
                    // });

                    // 新米编
                    $.each(beginner_guide, function(index, values) {
                        var beginner_guide = '<li>' +
                            '<a href="introduction-details.html?id=' + values.id + '">' +
                            '<h2>' + values.article_title + '</h2>' +
                            '<p>作者：' + values.origin + '</p>' +
                            '</a>' +
                            '</li>';
                        $('.beginner-guide').append(beginner_guide);
                    });

                    // 中级者编
                    $.each(intermediate, function(index, values) {
                        var intermediate = '<li>' +
                            '<a href="introduction-details.html?id=' + values.id + '">' +
                            '<h2>' + values.article_title + '</h2>' +
                            '<p>作者：' + values.origin + '</p>' +
                            '</a>' +
                            '</li>';
                        $('.intermediate').append(intermediate);
                    });

                    // // 形态介绍
                    // $.each(xingtai, function(index, values) {
                    //     var xingtai = '<li>'+
                    //         '<a href="introduction-details.html?id='+values.id+'">'+
                    //             '<h2>'+values.article_title+'</h2>'+
                    //             '<p>作者：'+values.origin+'</p>'+
                    //         '</a>'+
                    //     '</li>';
                    //     $('.xingtai').append(xingtai);
                    // });

                    // // 探索相关
                    // $.each(explore, function(index, values) {
                    //     var explore = '<li>'+
                    //         '<a href="introduction-details.html?id='+values.id+'">'+
                    //             '<h2>'+values.article_title+'</h2>'+
                    //             '<p>作者：'+values.origin+'</p>'+
                    //         '</a>'+
                    //     '</li>';
                    //     $('.explore').append(explore);
                    // });

                    // 进阶变强
                    $.each(jinjie, function(index, values) {
                        var jinjieHtml = '<li>' +
                            '<a href="news-details.html?id=' + values.id + '">' +
                            '<h2>' + values.article_title + '</h2>' +
                            '<p>作者：' + values.origin + '</p>' +
                            '</a>' +
                            '</li>';
                        $('.jinjie').append(jinjieHtml);
                    });

                    if (video.length !== 0) {
                        // 视频添加
                        $('.cartoon-ul .li-01').find('img').attr('src', video[0].thumbnail).attr('data', video[0].video_link);
                        $('.cartoon-ul .li-02').find('img').attr('src', video[1].thumbnail).attr('data', video[1].video_link);
                        $('.cartoon-ul .li-03').find('img').attr('src', video[2].thumbnail).attr('data', video[2].video_link);
                        $('.cartoon-ul .li-04').find('img').attr('src', video[3].thumbnail).attr('data', video[3].video_link);
                    }
                    _this.Textlength(28, 25);
                } else {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("サーバーに接続できません", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 全屏滚动判断
    scroll: function() {
        $(window).scroll(function() {
            var scrollTop = $(this).scrollTop();
            var scrollHeight = $(document).height();
            var windowHeight = $(this).height();
            $(document).scrollTop() > 400 ? $('.return-top').fadeIn() : $('.return-top').fadeOut();
        });

        $('.return-top').click(function(event) {
            $(document).scrollTop(0);
        });
    },
    // 首页轮播
    indexSwiper: function() {
        var mySwiper = new Swiper('.swiper-container', {
            pagination: '.swiper-pagination',
            direction: 'vertical',
            slidesPerView: 'auto',
            paginationClickable: true,
            spaceBetween: 0,
            mousewheelControl: true,
            resistanceRatio: 0,
            onInit: function(swiper) {
                swiperAnimate(swiper);

                function Ani(btn, page) {
                    $(btn).on('click', function() {
                        mySwiper.slideTo(page, 1000, true);
                    });
                };

                function navLink() {
                    for (var i = 6; i >= 0; i--) {
                        Ani('.nav-link-0' + i + ' .link-a', i);
                    };
                }
                navLink();
            },
            onSlideChangeEnd: function(swiper) {
                swiper.activeIndex >= 7 ? $('.rocket').fadeIn() : $('.rocket').fadeOut();
                swiperAnimate(swiper);
                swiper.activeIndex !== 0 ? $('.header').addClass('header-shadow') : $('.header').removeClass('header-shadow');
                $('.nav-link').removeClass('on')
                $('.nav-link-0' + swiper.activeIndex + '').addClass('on');
            },
        });

        if (go_page !== undefined) {
            mySwiper.slideTo(go_page - 1, 1000, true);
        }

        $('body').on('click', '.rocket', function(event) {
            var _this = $(this);
            _this.addClass('rocket-ani')
            mySwiper.slideTo(0, 1000, true);
            setTimeout(function() { _this.fadeOut() }, 1000);
            setTimeout(function() { _this.removeClass('rocket-ani') }, 1500);
        });
    },
    // 游戏咨询信息切换
    jqslideTxtBox: function() {
        // 首页第二页游戏介绍信息
        jQuery(".txtMarquee-top").slide({ mainCell: ".bd ul", autoPlay: true, effect: "topMarquee", vis: 15, interTime: 50, mouseOverStop: false });
        jQuery(".slideTxtBox").slide({ effect: "leftLoop", trigger: "click", easing: "easeOutElastic" });
        jQuery(".slideTxtBox-02").slide({ effect: "top", trigger: "click", easing: "easeOutElastic" });
    },
    // 内容字体长度超过40，标题字体长度超过25
    Textlength: function(x1, x2) {
        $('.slideTxtBox .bd p').each(function() {
            if ($(this).text().length > x1) {
                var str = $(this).text();
                $(this).text(str.substr(0, x1) + '...');
            }
        });
        $('.slideTxtBox .bd h2').each(function() {
            if ($(this).text().length > x2) {
                var str = $(this).text();
                $(this).text(str.substr(0, x2) + '...');
            }
        });
    },
    // 获取当前hash的具体参数
    getQueryVariable: function(variable) {
        var query = window.location.href.split('?')[1];
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    },
    // 视频和图片预览
    videoAndImg: function() {
        // 关闭视频 & 其他弹窗
        $('.popup').on('click', '.close-btn', function(event) {
            $('.video-wrap, .popup-video').fadeOut();
            $('.popup').fadeOut();
        });

        // 点击视频浏览
        $('.cartoon-ul').on('click', '.li-sp', function(event) {
            var _this = $(this);
            $('.video-wrap, .popup-video').fadeIn().find('iframe').attr('src', _this.find('img').attr('data')).removeClass('ani-btn');
        });

        $('.cartoon-ul').on('click', '.li-03', function(event) {
            $('.video-wrap, .popup-video').fadeIn();
        });
    },
    // 新闻最新接口
    newsZxList: function() {
        var cate_id, page, current, index;
        cate_id = obj.getQueryVariable('cate_id');
        page = obj.getQueryVariable('page');
        current = window.location.href.split("?")[0];
        index = layer.load(2, { shade: [0.1, '#fff'] });
        $('.news .tab-title .a-tab-0' + cate_id + '').addClass('on').siblings('a').removeClass('on');
        $.ajax({
            url: api + "/article",
            type: "GET",
            data: {
                cate_id: cate_id,
                page: page,
            },
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    var current_page = json.data.articleList.current_page; // 当前页数
                    var last_page = json.data.articleList.last_page; // 总共页数
                    var prev_page_url = json.data.articleList.prev_page_url; // 前一页
                    var next_page_url = json.data.articleList.next_page_url; // 后一页
                    var _data = json.data.articleList.data;
                    if (_data.length == 0) {
                        $('.main').addClass('list-null');
                    } else {
                        $('.main').removeClass('list-null');
                        // 渲染列表
                        $.each(_data, function(index, values) {
                            var dtHtml = '<li>' +
                                '<a title="' + values.article_title + '" href="news-details.html?id=' + values.id + '">' +
                                '<i></i><em></em>' +
                                '<p class="lside">' +
                                '<span class="title" style="display: block;">' + values.created_at + ' ' + values.article_title + '</span>' +
                                '<span class="comment">' + values.article_excerpt + '</span>' +
                                '</p>' +
                                '</a>' +
                                '</li>';
                            $('.new-list ul').append(dtHtml);
                        });
                        $('.current_page').html(current_page); // 当前页数
                        $('.last_page').html(last_page); // 总共页数
                        if (prev_page_url === null) { // 上一页
                            $('.front-link').hide(); // 没有第一页
                        } else {
                            $('.front-link').attr('href', current + '?cate_id=' + cate_id + '&page=' + prev_page_url.split("?page=")[1])
                        }
                        if (next_page_url === null) { // 下一页
                            $('.after-link').hide(); // 没有最后一页
                        } else {
                            $('.after-link').attr('href', current + '?cate_id=' + cate_id + '&page=' + next_page_url.split("?page=")[1])
                        }
                        $('.new-list').addClass('new-list-show');
                    }
                } else {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("サーバーに接続できません", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 游戏攻略接口
    newsIntrList: function() {
        var cate_id, page, current, index;
        cate_id = obj.getQueryVariable('cate_id');
        page = obj.getQueryVariable('page');
        current = window.location.href.split("?")[0];
        index = layer.load(2, { shade: [0.1, '#fff'] });
        $('.news .tab-title .a-tab-0' + cate_id + '').addClass('on').siblings('a').removeClass('on');
        $.ajax({
            // url: "https://tapi.qingcigame.com/jpweb/new/list",
            url: api + "/new/list",
            type: "GET",
            data: {
                // cate_id 最新-综合-活动
                // page    1...
                cate_id: cate_id,
                page: page,
            },
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    var current_page = json.data.current_page; // 当前页数
                    var last_page = json.data.last_page; // 总共页数
                    var prev_page_url = json.data.prev_page_url; // 前一页
                    var next_page_url = json.data.next_page_url; // 后一页
                    var _data = json.data.data;
                    if (_data.length == 0) {
                        $('.main').addClass('list-null');
                    } else {
                        $('.main').removeClass('list-null');
                        // 渲染列表
                        $.each(_data, function(index, values) {
                            var dtHtml = '<li>' +
                                '<a title="' + values.article_title + '" href="introduction-details.html?id=' + values.id + '">' +
                                '<i class="gl-icon"></i><em></em>' +
                                '<p class="lside">' +
                                '<span class="title" style="display: block;">' + values.created_at + ' ' + values.article_title + '</span>' +
                                '</p>' +
                                '</a>' +
                                '</li>';
                            $('.new-list ul').append(dtHtml);
                        });
                        $('.new-list').addClass('new-list-show');
                        $('.current_page').html(current_page); // 当前页数
                        $('.last_page').html(last_page); // 总共页数
                        if (prev_page_url === null) { // 上一页
                            $('.front-link').hide(); // 没有第一页
                        } else {
                            $('.front-link').attr('href', current + '?cate_id=' + cate_id + '&page=' + prev_page_url.split("?page=")[1])
                        }
                        if (next_page_url === null) { // 下一页
                            $('.after-link').hide(); // 没有最后一页
                        } else {
                            $('.after-link').attr('href', current + '?cate_id=' + cate_id + '&page=' + next_page_url.split("?page=")[1])
                        }
                    }

                } else {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("サーバーに接続できません", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 最新、综合、活动跳转
    newsTabPage: function() {
        $('.news .tab-title').on('click', 'a', function(event) {
            $(this).addClass('on').siblings('a').removeClass('on');
            var cate_id = $(this).attr('cate-id');
            window.location.href = window.location.href.split("?")[0] + '?cate_id=' + cate_id + '&page=1'
        });
    },
    // 新闻详情
    newsInfo: function() {
        var id = obj.getQueryVariable('id');
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        $.ajax({
            url: api + "/article/" + id + "",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                $('.news-det .main').addClass('main-show');
                if (json.code == 200) {
                    var newsTitle = json.data.list.article_title; // 标题
                    var detailsInfo = json.data.list.article_content; // 内容
                    var origin = json.data.list.origin; // 作者
                    var created_at = json.data.list.created_at; // 时间
                    $('.news .news-title h3, .current-title').html(newsTitle);
                    $('.news .origin').html(origin);
                    $('.news .created_at').html(created_at);
                    $('.details-info').append(detailsInfo);

                    // 翻页功能
                    obj.pageDown(json.data.last, json.data.next, 'id');

                    $('title').html(newsTitle + ' 最強でんでん - 株式会社QCPlay');
                    $('meta[name="keywords"]').attr('content', json.data.list.article_keywords);
                    $('meta[name="description"]').attr('content', json.data.list.article_excerpt);
                } else {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("サーバーに接続できません", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 翻页功能
    pageDown: function(lastPage, nextPage, cite) {
        var href = window.location.href.split("?")[0] + '?' + cite + '='
        if (lastPage !== '') $('.prev-page').addClass('show').attr('href', href + lastPage.id).attr('title', lastPage.article_title);
        if (nextPage !== '') $('.next-page').addClass('show').attr('href', href + nextPage.id).attr('title', nextPage.article_title);
    },
    // 获取同名动画视频
    sameNameAjax: function() {
        var id = obj.getQueryVariable('id');
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        $.ajax({
            url: api + "/article?cate_id=" + id + "&limit=0",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    // 渲染视频数量
                    $.each(json.data.articleList1, function(index, values) {
                        var dtHtml = '<li data="' + values.video_link + '" ><a href="javascript:;">' + Number(index + 1) + '</a></li>';
                        $('.outline-01 .select-ul').append(dtHtml);
                    });

                    $.each(json.data.articleList2, function(index, values) {
                        var dtHtml = '<li data="' + values.video_link + '" ><a href="javascript:;">' + Number(index + 1) + '</a></li>';
                        $('.outline-02 .select-ul').append(dtHtml);
                    });

                    var firstLink = json.data.articleList2[0].video_link;
                    $('.left-video iframe').attr('src', firstLink);

                    var number = $('.outline').length;
                    $('.right-select h3 span').html('1/' + number + '');

                    for (var i = number; i >= 1; i--) {
                        var videoUrl = $('.outline-0' + i + ' .select-ul').children("li").eq(0).addClass('on').attr('data');
                        $('.outline-0' + i + ' .left-video iframe').attr('src', videoUrl);
                        for (var n = 0; n <= $('.outline-0' + i + ' .select-ul li').length; n++) {
                            $('.outline-0' + i + ' .right-select span').html('1/' + n + '');
                        };
                    };


                } else {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("サーバーに接続できません", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 视频切换
    videoSwitch: function() {
        $('.select-ul').on('click', 'li', function(event) {
            var playSrc = $(this).attr('data');
            $(this).addClass('on').siblings().removeClass('on');
            $(this).parents('.right-select').siblings('.left-video').find('iframe').attr('src', playSrc);
        });
    },
    // 选择不同频道
    channel: function() {
        $('.label').on('click', 'h4', function(event) {
            $(this).addClass('on').siblings().removeClass('on').parent('.label').siblings('.int-wrap').children('.int-bg').eq($(this).index()).addClass('show').siblings().removeClass('show');
            $(this).parents('.introduce').siblings('.animation-content').children('.outline').eq($(this).index()).addClass('show').siblings().removeClass('show');
            tabVideo();
        });

        // 关闭所有视频
        function tabVideo() {
            var auVideo = document.getElementsByTagName("video");
            for (var i = 0; i < auVideo.length; i++) {
                auVideo[i].pause();
            }
        }
    },
    // 获取视频中心视频
    videoCenterAjax: function() {
        var id = obj.getQueryVariable('id');
        var limit = 5; // 精彩视频个数5个
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        // 精彩视频5个
        $.ajax({
            url: api + "/article?cate_id=" + id + "&limit=" + limit + "",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    // 渲染视频数量
                    $.each(json.data.articleList.data, function(index, values) {
                        var dtHtml = '<li data="' + values.video_link + '">' +
                            '<div class="pic"><span><img src="' + values.thumbnail + '"></span></div>' +
                            '<div class="name">' +
                            '<h4>' + values.article_title + '</h4>' +
                            '<p>' + values.article_excerpt + '</p>' +
                            '</div>' +
                            '</li>';
                        $('.video-content .select-ul').append(dtHtml);
                    });
                    // // 第一个视频默认选中，并播放
                    var oneLi = $('.video-center .select-ul').children("li").eq(0);
                    oneLi.addClass('on').attr('data');
                    $('.left-video iframe').attr('src', oneLi.attr('data'));
                } else {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("サーバーに接続できません", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })

        // 全部视频
        $.ajax({
            url: api + "/article?cate_id=" + id + "&limit=0",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    // 渲染视频数量
                    $.each(json.data.articleList, function(index, values) {
                        var dtHtml = '<li>' +
                            '<a href="video-details.html?id=' + values.id + '">' +
                            '<span>' +
                            '<img src="' + values.thumbnail + '">' +
                            '</span>' +
                            '</a>' +
                            '<p>' + values.article_title + '</p>' +
                            '</li>';
                        $('.more-videos ul').append(dtHtml);
                    });
                } else {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("サーバーに接続できません", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 获取详细视频
    videoDetailsAjax: function() {
        var id = obj.getQueryVariable('id');
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        $.ajax({
            url: api + "/article/" + id + "",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    var newsTitle = json.data.list.article_title; // 标题
                    var detailsInfo = json.data.list.article_content; // 内容
                    var origin = json.data.list.origin; // 作者
                    var created_at = json.data.list.created_at; // 时间
                    var video_link = json.data.list.video_link; // 视频
                    $('.video-center .news-title h3, .current-title').html(newsTitle);
                    $('.video-center .details-info').append(detailsInfo);
                    $('.video-center .origin').html(origin);
                    $('.video-center .created_at').html(created_at);
                    $('.video-info iframe').attr('src', video_link);
                    // 翻页功能
                    obj.pageDown(json.data.last, json.data.next, 'id');

                    $('title').html(newsTitle + ' 最強でんでん - 株式会社QCPlay');
                    $('meta[name="keywords"]').attr('content', json.data.list.article_keywords);
                    $('meta[name="description"]').attr('content', json.data.list.article_excerpt);
                } else {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("サーバーに接続できません", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 获取漫画续集信息
    comicSequelAjax: function() {
        var id = obj.getQueryVariable('id');
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        $.ajax({
            url: api + "/article?cate_id=" + id + "&limit=0",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    // 渲染漫画列表数量

                    $.each(json.data.articleList, function(index, values) {
                        var dtHtml = '<li class="ani-btn"><a href="news-details.html?id=' + values.id + '">' + values.article_title + '</a></li>';
                        $('.cartoon-list ul').append(dtHtml);
                    });
                    $('.cartoon-list ul li:nth-child(1)').html();
                    var info = $('.cartoon-list ul li:nth-child(1) a').html()
                    var href = $('.cartoon-list ul li:nth-child(1) a').attr('href');
                    $('.synopsis .start').attr('href', href).find('span').html(info);
                    // $('.cartoon-list span').html('全'+parseInt(json.data.articleList.length)+'話');

                } else {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("サーバーに接続できません", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // wiki-贵重物品-筛选
    filtering: function() {
        rarity = ''; // 品阶
        deviation = ''; // 五围
        halo_effect = ''; // 光环技能
        mosaic_skill = ''; // 镶嵌技能
        column = ''; // 筛选参数1
        order = ''; // 筛选参数2
        page = ''; // 第几页
        url = api + '/goods'; // 需要请求的地址

        // 第一次进入时候请求一次
        function wikiDataAjax(url) {
            $('.loading-wrap').fadeIn();
            $.ajax({
                url: url,
                type: "GET",
                data: {
                    rarity: rarity,
                    deviation: deviation,
                    halo_effect: halo_effect,
                    mosaic_skill: mosaic_skill,
                    column: column,
                    order: order,
                    page: page
                },
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    if (json.code == 200) {
                        var next_page_url = json.data.data.next_page_url; // 下一页的数据
                        // 渲染-所有贵重物品
                        // 橙色-1
                        // 蓝色-2
                        // 绿色-3
                        // 紫色-4
                        if (json.data.data.data.length !== 0) {
                            $.each(json.data.data.data, function(index, values) {
                                var addHtml = '<li class="li-color-0' + values.colour + '" titles="' + values.title + '"colour="' + values.colour + '" art="' + values.art + '"  culture="' + values.culture + '" describe="' + values.describe + '" deviation="' + values.deviation + '" faith="' + values.faith + '" get_type="' + values.get_type + '" halo_effect="' + values.halo_effect + '" id="' + values.id + '" mosaic_skill="' + values.mosaic_skill + '" popularity="' + values.popularity + '" quality="' + values.quality + '" rarity="' + values.rarity + '" rarity_order="' + values.rarity_order + '" technology="' + values.technology + '">' +
                                    '<div>' +
                                    '<img src="https://qcplay-platform.oss-cn-hangzhou.aliyuncs.com/qcgame-admin/snailValuables/' + values.id + '.png">' +
                                    '</div>' +
                                    '<span>' + values.title + '</span>' +
                                    '</li>';
                                $('.goods-list-wrap ul').append(addHtml);
                            });
                            // 如果下页有参数则显示更多按钮
                            next_page_url !== null ? $('.more-wrap').fadeIn().attr('data', next_page_url) : $('.more-wrap').hide();
                            $('.tips-wrap, .loading-wrap').hide();
                        } else {
                            layer.msg("お探しの貴重品が見つかりません", { time: 2000 });
                            $('.tips-wrap').fadeIn();
                        }
                        $('.loading-wrap').hide();
                    } else {
                        $('.loading-wrap').fadeIn().html('サーバーに接続できません');
                    }

                },
                error: function() {
                    $('.loading-wrap').fadeIn().html('サーバーに接続できません');
                },
                complete: function() {
                    // layer.close(index);
                }
            })
        }
        wikiDataAjax(url);

        // 点击选择按钮
        $('.option-selection-box .selection-box').on('click', 'li', function(event) {
            page = 1; // 每次点击按钮的时候回到第一页
            var _this = $(this);
            _this.addClass('on').siblings().removeClass('on');
            getLiData(); // 获取条件数据
            $('.goods-list-wrap ul').find('li').remove(); // 先清除一次再渲染
            wikiDataAjax(url); // 选择条件数据后ajax请求            
        });


        // 点击更多查看下一页
        $('.more-wrap').click(function(event) {
            page = $(this).attr('data').split("?page=")[1];
            getLiData(); // 获取条件数据
            wikiDataAjax(url) // 选择条件数据后ajax请求
        });

        // 获取数据信息
        function getLiData() {
            rarity = $('.grade').find('.on').attr('data'); // 品阶
            deviation = $('.dimension').find('.on').attr('data'); // 五围
            halo_effect = $('.halo_effect').find('.on').attr('data'); // 光环技能
            mosaic_skill = $('.mosaic_skill').find('.on').attr('data'); // 镶嵌技能
            column = $('.grade_array').find('.on').attr('column'); // 筛选参数1
            order = $('.grade_array').find('.on').attr('order'); // 筛选参数2
        }

        // wiki-贵重物品-筛选-复位清除选项
        $('.reset-btn').click(function(event) {
            $('.goods-list-wrap ul').find('li').remove();
            $('.selection-box ul li:nth-child(1)').addClass('on').siblings().removeClass('on');
            rarity = ''; // 品阶
            deviation = ''; // 五围
            halo_effect = ''; // 光环技能
            mosaic_skill = ''; // 镶嵌技能
            column = ''; // 筛选参数1
            order = ''; // 筛选参数2
            page = ''; // 第几页
            url = api + "/goods"; // 需要请求的地址
            wikiDataAjax(url)
        });
    },
    // 信息切换-关闭弹窗清除数据
    wikiTabInfo: function() {
        $('.info-box .tit').on('click', 'h4', function(event) {
            $(this).addClass('on').siblings().removeClass('on').parent('.tit').siblings('.con').children('div').eq($(this).index()).addClass('show').siblings().removeClass('show');
        });
    },
    // 贵重物品弹窗
    wikiPopup: function() {
        // 开启弹窗
        $('.goods-list-wrap').on('click', 'li', function(event) {
            $('.wiki-popup').fadeIn();
            var title = $(this).attr('titles'); // 标题
            var colour = $(this).attr('colour'); // 颜色
            var art = $(this).attr('art'); // 艺术
            var culture = $(this).attr('culture'); // 文化
            var describe = $(this).attr('describe'); // 简介
            var deviation = $(this).attr('deviation'); // 偏向
            var faith = $(this).attr('faith'); // 信仰
            var get_type = $(this).attr('get_type'); // 获取途径  
            var halo_effect = $(this).attr('halo_effect'); // 光环技能
            var id = $(this).attr('id'); // 图片id
            var mosaic_skill = $(this).attr('mosaic_skill'); // 镶嵌技能
            var popularity = $(this).attr('popularity'); // 人气
            var quality = $(this).attr('quality');
            var rarity = $(this).attr('rarity');
            var rarity_order = $(this).attr('rarity_order');
            var technology = $(this).attr('technology'); // 科技

            if (rarity == '无') rarity = '' // 无不输出

            $('.wiki-wrap h1').html(title); // 标题
            $('.wiki-wrap .span-pj').html(quality + rarity); // 颜色
            $('.wiki-wrap .span-px').html(deviation); // 偏向

            $('.wiki-wrap .pic-img img').attr('src', 'https://qcplay-platform.oss-cn-hangzhou.aliyuncs.com/qcgame-admin/snailValuables/' + id + '.png')
            $('.wiki-wrap .pic-img').addClass('pic-img-0' + colour + ''); // 框框颜色

            if (halo_effect == '') halo_effect = '暂无数据'
            if (mosaic_skill == '') mosaic_skill = '暂无数据'
            if (get_type == '') get_type = '暂无数据'
            if (describe == '') describe = '暂无数据'
            $('.wiki-wrap .p-01').html(halo_effect); // 光环技能
            $('.wiki-wrap .p-02').html(mosaic_skill); // 镶嵌技能
            $('.wiki-wrap .p-03').html(get_type); // 镶嵌技能
            $('.wiki-wrap .p-04').html(describe); // 简介

            $('.wiki-wrap .data-ul .li-01').html(popularity); // 人气
            $('.wiki-wrap .data-ul .li-02').html(art); // 艺术
            $('.wiki-wrap .data-ul .li-03').html(faith); // 信仰
            $('.wiki-wrap .data-ul .li-04').html(culture); // 文化
            $('.wiki-wrap .data-ul .li-05').html(technology); // 科技

            if (colour == 1) colour = '#f9ab61'
            if (colour == 2) colour = '#9ECBD2'
            if (colour == 3) colour = '#b4cf7b'
            if (colour == 4) colour = '#DCA8C7'
            myChart(popularity, art, faith, culture, technology, colour);
        });

        // 关闭弹窗
        $('.wiki-popup').on('click', '.close-btn', function(event) {
            $('.wiki-popup').fadeOut();
            setTimeout(function() {
                for (var i = 4; i >= 0; i--) $('.wiki-wrap .pic-img').removeClass('pic-img-0' + i + ''); // 框框颜色
                $('.wiki-wrap .pic-img img').attr('src', '');
                $('.wiki-wrap h1, .wiki-wrap h2 span').html(''); // 清空标题/颜色
                $('.wiki-wrap .con p').html(''); // 清空光环技能/镶嵌技能/镶嵌技能/简介
                $('.wiki-wrap .data-ul li').html(''); // 清空/人气/艺术/信仰/文化/科技
                $('.wiki-wrap .tit .h4-01').addClass('on').siblings().removeClass('on'); // tab恢复
                $('.wiki-wrap .con div:nth-child(1)').addClass('show').siblings().removeClass('show'); // tab恢复
            }, 500)
        });

        function myChart(popularity, art, faith, culture, technology, colour) {
            //指定图表的配置项和数据
            option = {
                tooltip: {},
                radar: {
                    center: ['50%', '60%'],
                    startAngle: 90,
                    name: {
                        formatter: '{value}',
                        textStyle: {
                            color: '#937f80',
                            fontSize: '12',
                            lineHeight: 14,
                            padding: [0, 0],
                        },
                        formatter: function(data, indicator) {
                            var data0 = data.substring(0, 2)
                            var data1 = data.substring(2)
                            if (data1 == maxData) indicator.nameTextStyle.color = colour
                            return data0 + '\n' + data1
                                // return val.split(":").join("\n");
                        },
                    },
                    // 指示器名称和指示器轴的距离。[ default: 5 ]
                    nameGap: 5,
                    // 雷达图圈数设置
                    splitNumber: 2,
                    // 设置雷达图中间射线的颜色
                    axisLine: {
                        lineStyle: {
                            width: 3,
                            color: '#ae988b',
                        },
                    },
                    splitArea: {
                        show: true,
                        areaStyle: {
                            color: '#EDDCC4', // 图表背景的颜色
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            width: 3,
                            color: '#ac998b', // 设置网格的颜色
                        },
                    },
                    indicator: [
                        { name: '人气' + popularity, max: 120 },
                        { name: '科技' + technology, max: 120 },
                        { name: '文化' + culture, max: 120 },
                        { name: '信仰' + faith, max: 120 },
                        { name: '艺术' + art, max: 120 },
                    ]
                },
                series: [{
                    type: 'radar',
                    itemStyle: { // 折线拐点标志的样式。
                        normal: { // 普通状态时的样式
                            lineStyle: {
                                width: 3,
                                color: colour,
                            },
                        },
                    },
                    data: [{
                        value: [popularity, technology, culture, faith, art],
                        itemStyle: { // 单个拐点标志的样式设置。
                            normal: {
                                color: 'rgba(60,135,213,.3)',
                                opacity: 0.2,
                                // 拐点的描边颜色。[ default: '#000' ]
                                borderWidth: 1,
                                // 拐点的描边宽度，默认不描边。[ default: 0 ]
                            }
                        },
                        areaStyle: {
                            normal: {
                                color: colour // 选择区域颜色
                            }
                        }

                    }, ],
                }]
            };
            var maxData = Math.max.apply(this, option.series[0].data[0].value)
                //获取dom容器
            var myChart = echarts.init(document.getElementById('chartmain'));
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        }
    },
    // wiki-贵重物品-搜索
    wikiearch: function() {
        var _this = this;
        $('.search-btn').on('click', function(event) {
            var searchIpu = $('.search-input').val()
            if (searchIpu == '') {
                layer.msg("検索するIDをを入力してください", { time: 2000 });
                return
            }
            var index = layer.load(2, { shade: [0.1, '#fff'] });
            $.ajax({
                url: api + "/goods",
                type: "GET",
                data: {
                    title: searchIpu
                },
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    if (json.code == 200) {
                        var next_page_url = json.data.data.next_page_url; // 下一页的数据
                        // 如果下页有参数则显示更多按钮
                        next_page_url !== null ? $('.more-wrap').fadeIn().attr('data', next_page_url) : $('.more-wrap').hide();

                        $('.search-input').val('') // 搜索完成后搜索栏目清空
                            // 清除信息
                        var gradeSet = resetSet('.grade');
                        var dimensionSet = resetSet('.dimension');
                        var halo_effectSet = resetSet('.halo_effect');
                        var mosaic_skillSet = resetSet('.mosaic_skill');
                        var grade_arraySet = resetSet('.grade_array');

                        function resetSet(li) {
                            $(li).find('li').removeClass('on');
                        }

                        $('.goods-list-wrap ul').find('li').remove();
                        if (json.data.data.data == '') {
                            layer.msg("お探しの貴重品が見つかりません", { time: 2000 });
                            $('.tips-wrap').fadeIn();
                        } else {
                            $.each(json.data.data.data, function(index, values) {
                                var addHtml = '<li class="li-color-0' + values.colour + '" titles="' + values.title + '"colour="' + values.colour + '" art="' + values.art + '" culture="' + values.culture + '" describe="' + values.describe + '" deviation="' + values.deviation + '" faith="' + values.faith + '" get_type="' + values.get_type + '" halo_effect="' + values.halo_effect + '" id="' + values.id + '" mosaic_skill="' + values.mosaic_skill + '" popularity="' + values.popularity + '" quality="' + values.quality + '" rarity="' + values.rarity + '" rarity_order="' + values.rarity_order + '" technology="' + values.technology + '">' +
                                    '<div>' +
                                    '<img src="https://qcplay-platform.oss-cn-hangzhou.aliyuncs.com/qcgame-admin/snailValuables/' + values.id + '.png">' +
                                    '</div>' +
                                    '<span>' + values.title + '</span>' +
                                    '</li>';
                                $('.goods-list-wrap ul').append(addHtml);
                            });
                            $('.tips-wrap').hide();
                        }
                    }
                },
                error: function() {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                },
                complete: function() {
                    layer.close(index);
                }
            })
        });
    },
    // 获取需要筛选的wiki参数
    getWikiData: function() {
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        $.ajax({
            url: api + "/get_type",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    // 渲染-wiki-光环技能的参数
                    $.each(json.data.data.halo_effect, function(index, values) {
                        var addHtml = '<li data="' + values + '">' + values + '</li>';
                        $('.halo_effect').append(addHtml);
                    });

                    // 渲染-wiki-镶嵌技能的参数
                    $.each(json.data.data.mosaic_skill, function(index, values) {
                        var addHtml = '<li data="' + values + '">' + values + '</li>';
                        $('.mosaic_skill').append(addHtml);
                    });
                    $('.halo_effect, .mosaic_skill').find('li:nth-child(1)').addClass('on').attr('data', '');
                } else {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("サーバーに接続できません", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // wikiSwiper的轮播
    wikiSwiper: function() {
        var swiper = new Swiper('.swiper-container', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            spaceBetween: 30,
        });
    },
    wikiIndexAjax: function() {
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        var _this = this;
        $.ajax({
            url: api + "/getdata",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    var ditu = json.data.articleList.ditu; // 地图探索
                    var hot = json.data.articleList.hot; // 热门
                    var xinshou = json.data.articleList.xinshou.data; // 新手
                    var xingtai = json.data.articleList.xingtai; // 形态
                    var hot = json.data.articleList.hot; // 热门
                    var banner = json.data.articleList.banner; // 轮播
                    var index = json.data.articleList.index; // 轮播

                    // // 渲染-地图探索
                    $.each(banner, function(index, values) {
                        var addHtml = '<div class="swiper-slide">' +
                            '<a href="' + values.url + '">' +
                            '<img src="' + values.mobile_background + '">' +
                            '</a>' +
                            '</div>';
                        $('.swiper-container .swiper-wrapper').append(addHtml);
                    });
                    _this.wikiSwiper();

                    // 渲染-地图探索
                    $.each(ditu, function(index, values) {
                        var addHtml = '<li><a href="news-details.html?id=' + values.id + '"><img src="' + values.thumbnail + '"><span>' + values.article_title + '</span></a></li>';
                        $('.map-exploration ul').prepend(addHtml);
                    });

                    $('.map-exploration li:nth-child(1)').find('span').html('高麗');
                    $('.map-exploration li:nth-child(2)').find('span').html('大和');
                    $('.map-exploration li:nth-child(3)').find('span').html('中華');
                    $('.map-exploration li:nth-child(4)').find('span').html('米国');
                    $('.map-exploration li:nth-child(5)').find('span').html('英伦');

                    // 渲染-热门
                    $.each(hot, function(index, values) {
                        var addHtml = '<li><a href="news-details.html?id=' + values.id + '">' + values.article_title + '</a></li>';
                        $('.hot-pages ul').append(addHtml);
                    });
                    // 新手指南
                    for (var i = $('.novice-guide li').length - 1; i >= 0; i--) {
                        $('.novice-guide .li-0' + i + ' a').attr('href', 'news-details.html?id=' + xinshou[i].id + '');
                    };

                    // 渲染-形态
                    $.each(xingtai, function(index, values) {
                        var addHtml = '<li><a href="news-details.html?id=' + values.id + '"><img src="' + values.thumbnail + '"><span>' + values.article_title + '</span></a></li>';
                        $('.form-intr ul').prepend(addHtml);
                    });

                    // 先固定写死
                    $('.form-intr li:nth-child(1)').find('span').html('ゾンビ');
                    $('.form-intr li:nth-child(2)').find('span').html('悪魔');
                    $('.form-intr li:nth-child(3)').find('span').html('天使');
                    $('.form-intr li:nth-child(4)').find('span').html('メカ');
                    $('.form-intr li:nth-child(5)').find('span').html('異形');
                    $('.form-intr li:nth-child(6)').find('span').html('ドラゴン');

                    // // 游戏攻略
                    $.each(index, function(index, values) {
                        var addHtml = '<li><a href="news-details.html?id=' + values.id + '"><span>' + values.cate_id + '</span><p>' + values.article_title + '</p></a></li>';
                        $('.game-intr ul').append(addHtml);
                    });

                } else {
                    layer.msg("サーバーに接続できません", { time: 2000 });
                }
            },
            error: function() {
                layer.msg("サーバーに接続できません", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    pageShow: function() {
        $('.page').addClass('pageShow');
    },
    share: function() {
        $.ajax({
            url: api + "/wechat/jssdk",
            type: "POST",
            data: {
                uri: window.location.href,
            },
            success: function(json) {
                if (json.code == 200) {
                    var ua = navigator.userAgent.toLowerCase();
                    if (ua.match(/MicroMessenger/i) == "micromessenger") {
                        wx.config(json.data);
                        wx.ready(function() {
                            wx.updateAppMessageShareData({
                                title: '最强蜗牛', // 分享标题
                                desc: '大家都爱玩的沙雕手游', // 分享描述
                                link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: 'https://static.qcplay.com/common/snail_icon.png', // 分享图标
                                success: function() {}
                            })

                            wx.updateTimelineShareData({
                                title: '最强蜗牛-大家都爱玩的沙雕手游', // 分享标题
                                link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                imgUrl: 'https://static.qcplay.com/common/snail_icon.png', // 分享图标
                                success: function() {
                                    // 设置成功
                                }
                            })
                        });
                        return;
                    } else {
                        try {
                            setShareInfo({
                                title: '最强蜗牛', // 分享标题
                                summary: '大家都爱玩的沙雕手游', // 分享内容
                                pic: 'https://static.qcplay.com/common/snail_icon.png', // 分享图片
                                url: window.location.href, // 分享链接
                                // 微信权限验证配置信息，若不在微信传播，可忽略
                                WXconfig: {
                                    swapTitleInWX: true, // 是否标题内容互换（仅朋友圈，因朋友圈内只显示标题）
                                    appId: json.data.appId, // 公众号的唯一标识
                                    timestamp: json.data.timestamp, // 生成签名的时间戳
                                    nonceStr: json.data.nonceStr, // 生成签名的随机串
                                    signature: json.data.signature // 签名
                                }
                            });
                        } catch (err) {}
                    }
                }
            }
        })
    },
    twitterShare: function() {
        // 插入推特推广
        $('.twitter-box').append('<a class="twitter-timeline" data-lang="ja" href="https://twitter.com/supersnail_jp">Tweets by MARVELOUS_SNAIL</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>');
        window.twttr = (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0],
                t = window.twttr || {};
            if (d.getElementById(id)) return t;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://platform.twitter.com/widgets.js";
            fjs.parentNode.insertBefore(js, fjs);

            t._e = [];
            t.ready = function(f) {
                t._e.push(f);
            };
            return t;
            var _iframe = document.createElement('iframe').contentWindow.document.body.find('a') //get iframe下的id
            _iframe.style.opacity = 0; //修改样式
        }(document, "script", "twitter-wjs"));
    }
}